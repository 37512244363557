
<template>
	<div class="main-content">
		<ktv-breadcrumb title="Weather List" page="Weather" folder="Weather List" />
		<ktv-table
			:columns="columns"
			:is-loading="loading"
			:line-numbers="true"
			:rows="rows"
			:total-rows="totalRecords"
			:mode="modeTable"
			:filter="false"
			@on-column-filter="onColumnFilter"
			@on-page-change="onPageChange"
			@on-per-page-change="onPerPageChange"
			@on-search="onSearchFilter"
			@on-sort-change="onSortChange"
		>
			<template #actionleft>
				<ktv-button text="Add Weather" color="green-light" icon="add" @click="openWeatherModal()" />
			</template>


			<template #rows="{ props }">
				<span v-if="props.column.field == 'button'">
					<b-dropdown
						size="xs"
						variant="link"
						toggle-class="text-decoration-none"
						no-caret
					>
						<template #button-content>
							<span class="btndropdownaction">
								<img :src="icondropdown">
							</span>
						</template>
						<b-dropdown-item class="dropdownitem-action" @click="openWeatherModal(props.row)">
							<img :src="iconedit" class="imgicon"> Update
						</b-dropdown-item>
						<b-dropdown-item class="dropdownitem-action" @click="deleteWeather(props.row)">
							<img :src="icondelete" class="imgicon"> Delete
						</b-dropdown-item>
					</b-dropdown>
				</span>

				<!-- <span v-if="props.column.field == 'button'">
					<a v-b-tooltip.hover href="#" title="Edit & Detail" @click="openWeatherModal(props.row)">
						<i class="i-Pen-3 text-18 text-success mr-2" />
						{{ props.row.button }}</a>
					<a v-b-tooltip.hover href="#" title="Delete" @click="deleteWeather(props.row)">
						<i class="i-Close-Window text-18 text-danger" />
						{{ props.row.button }}</a>
				</span> -->
				<span v-else-if="props.column.field == 'Status'">
					<b-badge :variant="props.row.Status === 'PUBLISHED' ? 'success' : 'warning'">{{ props.row.Status }}</b-badge>
				</span>
			</template>
		</ktv-table>

		<!-- MODAL-->
		<ktv-modal v-model="weatherModal" size="lg" :title="weatherModalTitle" no-close-on-backdrop no-close-on-esc @close="closeWeatherModal()">
			<template #content>
				<vcl-list v-show="loadingDetail" />
				<b-form v-show="!loadingDetail">
					<b-row>
						<b-form-group class="col-md-6">
							Year <span class="text-danger">*</span>
							<b-form-select
								v-model="weatherForm.Year"
								v-validate="'required'"
								:options="years"
								size="sm"
								name="year"
								:state="validateState('year')"
							/>
							<b-form-invalid-feedback>{{ veeErrors.first("year") }}</b-form-invalid-feedback>
						</b-form-group>
						<b-form-group class="col-md-6">
							Commodity <span class="text-danger">*</span>
							<b-form-select
								v-model="weatherForm.Commodity"
								v-validate="'required'"
								:options="commodities"
								size="sm"
								name="commodity"
								:state="validateState('commodity')"
							/>
							<b-form-invalid-feedback>{{ veeErrors.first("commodity") }}</b-form-invalid-feedback>
						</b-form-group>
						<b-form-group class="col-md-6">
							Country <span class="text-danger">*</span>
							<b-form-select
								v-model="weatherForm.Country"
								v-validate="'required'"
								:options="countries"
								size="sm"
								name="country"
								:state="validateState('country')"
							/>
							<b-form-invalid-feedback>{{ veeErrors.first("country") }}</b-form-invalid-feedback>
						</b-form-group>
						<!-- <b-form-group class="col-md-6">
							Province <span class="text-danger">*</span>
							<v-select
								v-model="weatherForm.Province"
								v-validate="'required'"
								label="text"
								name="province"
								multiple
								:options="provinces"
								:reduce="(text) => text.value"
								:state="validateState('province')"
								@change="changeProv($event)"
							/>
							<small class="text-danger">
								{{ veeErrors.first("province") }}
							</small>
						</b-form-group> -->

						<b-form-group class="col-md-6">
							Province <span class="text-danger">*</span>
							<treeselect 
								v-model="weatherForm.Province" 
								v-validate="'required'" 
								:multiple="true" 
								:options="arrProvinces" 
								name="province"
								
								:value-consists-of="valueConsistsOf"
								:state="validateState('province')"
							/>
							<small class="text-danger">
								{{ veeErrors.first("province") }}
							</small>
						</b-form-group>

						<b-form-group class="col-md-6">
							Month <span class="text-danger">*</span>
							<v-select
								v-model="weatherForm.Month"
								v-validate="'required'"
								:state="validateState('month')"
								label="text"
								name="month"
								multiple
								:options="months"
								:reduce="(text) => text.value"
							/>
							<small class="text-danger">
								{{ veeErrors.first("month") }}
							</small>
						</b-form-group>
						<b-form-group class="col-md-6">
							Plant Phase <span class="text-danger">*</span>
							<b-form-select
								v-model="weatherForm.PlantPhase"
								v-validate="'required'"
								:options="plantPhases"
								size="sm"
								name="plant phase"
								:state="validateState('plant phase')"
							/>
							<b-form-invalid-feedback>{{ veeErrors.first("plant phase") }}</b-form-invalid-feedback>
						</b-form-group>
						<b-form-group class="col-md-6">
							Humidity <span class="text-danger">*</span>
							<b-form-select
								v-model="weatherForm.Humidity"
								v-validate="'required'"
								:options="humidities"
								size="sm"
								name="humidity"
								:state="validateState('humidity')"
							/>
							<b-form-invalid-feedback>{{ veeErrors.first("humidity") }}</b-form-invalid-feedback>
						</b-form-group>
						<b-form-group class="col-md-6">
							Event <span class="text-danger">*</span>
							<b-form-select
								v-model="weatherForm.Event"
								v-validate="'required'"
								:options="events"
								size="sm"
								name="event"
								:state="validateState('event')"
							/>
							<b-form-invalid-feedback>{{ veeErrors.first("event") }}</b-form-invalid-feedback>
						</b-form-group>
						<b-form-group class="col-md-6">
							RainFall Trigger <span class="text-danger">*</span>
							<b-form-select
								v-model="weatherForm.RainFall"
								v-validate="'required'"
								:options="rainfallTriggers"
								size="sm"
								name="rainfall trigger"
								:state="validateState('rainfall trigger')"
							/>
							<b-form-invalid-feedback>{{ veeErrors.first("rainfall trigger") }}</b-form-invalid-feedback>
						</b-form-group>
						<b-form-group class="col-md-6">
							Temperatur Trigger <span class="text-danger">*</span>
							<b-form-select
								v-model="weatherForm.Temp"
								v-validate="'required'"
								:options="temperaturTriggers"
								size="sm"
								name="temperatur trigger"
								:state="validateState('temperatur trigger')"
							/>
							<b-form-invalid-feedback>{{ veeErrors.first("temperatur trigger") }}</b-form-invalid-feedback>
						</b-form-group>
					</b-row>
					<b-card class="mt-3">
						<!-- <vcl-facebook /> -->
						<b-tabs content-class="mt-3">
							<b-tab v-for="(v, i) in weatherForm.languages" :key="i" @click="changeTab(i)">
								<template #title>
									{{ getLanguageName(v.WeatherLanguageCode) }}
									<b-icon v-show="v.WeatherLanguageCode !== 'in'" icon="x" class="cursor-pointer" @click="deleteLanguageTab(i)" />
								</template>
								<b-row role="group">
									<b-form-group class="col-md-6">
										Do Headline <span class="text-danger">*</span>
										<b-form-select
											v-model="weatherForm.languages[i].WeatherLangDoHeadline"
											v-validate="'required'"
											:options="headlines"
											size="sm"
											name="do headline"
											:state="validateState('do headline')"
											:disabled="i !== 0"
										/>
										<b-form-invalid-feedback>{{ veeErrors.first("do headline") }}</b-form-invalid-feedback>
									</b-form-group>
									<b-form-group class="col-md-6">
										Do Description <span class="text-danger">*</span>
										<b-form-textarea
											id="textarea"
											v-model="weatherForm.languages[i].WeatherLangDoDescription"
											v-validate="'required'"
											placeholder="Enter something..."
											rows="3"
											max-rows="6"
											name="do description"
											:state="validateState('do description')"
										/>
										<b-form-invalid-feedback>{{ veeErrors.first("do description") }}</b-form-invalid-feedback>
									</b-form-group>
									<b-form-group class="col-md-6">
										Dont Headline <span class="text-danger">*</span>
										<b-form-select
											v-model="weatherForm.languages[i].WeatherLangDontHeadline"
											v-validate="'required'"
											:options="headlines"
											size="sm"
											name="dont headline"
											:state="validateState('dont headline')"
											:disabled="i !== 0"
										/>
										<b-form-invalid-feedback>{{ veeErrors.first("dont headline") }}</b-form-invalid-feedback>
									</b-form-group>
									<b-form-group class="col-md-6">
										Dont Description <span class="text-danger">*</span>
										<b-form-textarea
											id="textarea"
											v-model="weatherForm.languages[i].WeatherLangDontDescription"
											v-validate="'required'"
											placeholder="Enter something..."
											rows="3"
											max-rows="6"
											name="dont description"
											:state="validateState('dont description')"
										/>
										<b-form-invalid-feedback>{{ veeErrors.first("dont description") }}</b-form-invalid-feedback>
									</b-form-group>
								</b-row>
							</b-tab>
							<!-- New Tab Button (Using tabs-end slot) -->
							<template #tabs-end>
								<b-nav-item role="presentation" href="#" @click.prevent="openFormLanguageList">
									<b class="text-success">+ Language</b>
								</b-nav-item>
							</template>
							<!-- Render this if no tabs -->
							<template #empty>
								<div class="text-center text-muted">
									There are no open tabs<br>
									Open a new tab using the <b>+ Language</b> button above.
								</div>
							</template>
						</b-tabs>
					</b-card>
				</b-form>
			</template>
			<template #footer>
				<ktv-button color="primary" size="sm" class="float-right" text="Publish" @click="saveWeather(weatherForm, 'PUBLISHED')" />
				<b-button variant="outline-primary" size="sm" class="float-right mr-2" @click="saveWeather(weatherForm, 'DRAFT')"> Save Draft </b-button>
				<ktv-button color="light" size="sm" class="float-right mr-2" text="Cancel" @click="closeWeatherModal" />
			</template>
		</ktv-modal>

		<ktv-modal v-model="formLanguageList" size="sm" :title="formLanguageListTitle" no-close-on-backdrop no-close-on-esc @close="closeFormLanguageList">
			<template #content>
				<b-form-group>
					<b-form-select v-model="languageSelected" size="lg" :options="filteredLanguages" :select-size="10" />
				</b-form-group>
			</template>
			<template #footer>
				<b-button variant="primary" size="sm" class="float-right" @click="addLanguage(languageSelected)"> Add </b-button>
			</template>
		</ktv-modal>
	</div>
</template>
<script>
	import { KtvBreadcrumb, KtvTable, KtvButton, KtvModal } from "@/components"
	import { mapActions, mapGetters, mapMutations } from "vuex"

	// import the component
	import Treeselect from '@riophae/vue-treeselect'
	// import the styles
	import '@riophae/vue-treeselect/dist/vue-treeselect.css'
	export default {
		metaInfo: {
			title: "Weather Advisory",
		},
		components: { KtvBreadcrumb, KtvTable, KtvButton, KtvModal, Treeselect},
		data() {
			return {
				/* eslint-disable global-require */
				iconedit: require("@/assets/images/icon/iconedit.png"),
				icondelete: require("@/assets/images/icon/icondelete.png"),
				iconview: require("@/assets/images/icon/iconview.png"),
				icondropdown: require("@/assets/images/icon/icondropdown.png"),
				/* eslint-disable global-require */
				loading: false,
				columns: [
					{
						label: "Action",
						field: "button",
						html: true,
						tdClass: "text-center",
						thClass: "text-center",
						sortable: false
					},
					{
						label: "Year",
						field: "Year",
						thClass: "text-left",
					},
					{
						label: "Month",
						field: "Month",
						thClass: "text-left",
					},
					{
						label: "Country",
						field: "CountryLang",
						thClass: "text-left",
					},
					{
						label: "Province",
						field: "Province",
						thClass: "text-left",
					},
					// {
					// 	label: "District",
					// 	field: "TrainingTitle",
					// 	thClass: "text-left",
					// },
					{
						label: "Humidity",
						field: "Humidity",
						thClass: "text-left",
					},
					{
						label: "Event",
						field: "Event",
						thClass: "text-left",
					},
					{
						label: "Temp",
						field: "Temp",
						thClass: "text-left",
					},
					{
						label: "RainFall",
						field: "RainFall",
						thClass: "text-left",
					},
					{
						label: "Status",
						field: "Status",
						thClass: "text-left",
					},
				],
				rows: [],
				mainData: {
					lists: [],
					total: 0,
				},

				// arrProvinces: [],
				arrProvinces: [{
					id: '0',
					label: 'All Province',
					children: []
				}],
				valueConsistsOf: 'LEAF_PRIORITY',
				valueConsistsOfComp: 'BRANCH_PRIORITY',

				modeTable: "remote",
				serverParams: {
					rowPerpage: 10,
					currentPage: 1,
					columnFilters: { SubCategoryLanguageText: "" },
					sort: [
						{
							field: "",
							type: "ASC",
						},
					],
				},
				totalRecords: 0,
				/// //////
				weatherModal: false,
				weatherForm: {
					Year: null,
					Commodity: null,
					Country: null,
					Province: [],
					Month: [],
					PlantPhase: null,
					Humidity: null,
					Event: null,
					Temp: null,
					RainFall: null,
					languages: [
						{
							WeatherLanguageCode: "in",
							WeatherLangDoHeadline: "",
							WeatherLangDontHeadline: "",
							WeatherLangDoDescription: "",
							WeatherLangDontDescription: "",
						},
					],
					Status: null,
				},
				// years: [{ value: "2020", text: "2020" }],
				countries: [{ value: "in", text: "Indonesia" }],
				commodities: [],

				// provinces:[
				// 	{text : 'All', value : 'All'}
				// ],

				formLanguageList: false,
				languageSelected: null,
				languages: [],
				filteredLanguages: [],
				headlines: [
					{ value: "Weeding", text: "Weeding" },
					{ value: "Fertilization", text: "Fertilization" },
					{ value: "Prunning", text: "Prunning" },
					{ value: "Chemical weeding", text: "Chemical weeding" },
					{ value: "Making drainage", text: "Making drainage" },
					{ value: "Any activity", text: "Any activity" },
					{ value: "Check your plants", text: "Check your plants" },
					{ value: "Watering", text: "Watering" },
					{ value: "Apply chemical", text: "Apply chemical" },
					{ value: "Drying", text: "Drying" },
					{ value: "Harvesting", text: "Harvesting" },
					{ value: "Making compost", text: "Making compost" },
					{ value: "Pest and Disease control", text: "Pest and Disease control" },
				],
				id: null,
				loadingDetail: false,
				WeatherAdvisoryID: null,
			}
		},
		computed: {
			...mapGetters({
				provinces: "MASTER/getProvince",
				districts: "MASTER/getSubDistrict",
				temperaturTriggers: "WEATHER/getOptionTemp",
				rainfallTriggers: "WEATHER/getOptionRainfall",
				events: "WEATHER/getOptionEvent",
				humidities: "WEATHER/getOptionHumidity",
				plantPhases: "WEATHER/getOptionPlantPhase",
				months: "WEATHER/getOptionMonth",
				TotalRecords: "WEATHER/getTotalRecords",
			}),
			weatherModalTitle() {
				return "Add Weather"
			},
			formLanguageListTitle() {
				return "Add Language"
			},
			years() {
				let years = []
				let years5Ago = new Date().getFullYear() - 5
				for (let index = years5Ago; index <= years5Ago + 10; index++) {
					years.push({
						text: index,
						value: index,
					})
				}
				return years
			},
		},
		watch: {
			"weatherForm.languages": {
				immediate: true,
				handler: function (v) {
					let langs = v.map((v2) => v2.WeatherLanguageCode)
					let temp = this.languages.filter((val) => !langs.includes(val.value))
					this.filteredLanguages = temp.length ? temp : this.languages
				},
			},

			provinces(values) {				
				if (values.length === 0)
					return

				this.getProvince(values)           
			}
		},
		created() {
			this.getLanguages()
			this.getCommodities()
			
		},
		mounted() {
			this.loadItems()
			this.loadOption()
			
		},
		methods: {
			...mapActions("MASTER", ["actGetProvince", "actGetDistrict"]),
			...mapActions("WEATHER", ["actGetOption"]),
			...mapActions({
				getTablesGrid: "WEATHER/ActionTablesGrid",
			}),
			...mapMutations("MASTER", ["clearRegion"]),
			showLoadingMessage(message) {
				this.$swal.fire({
					title: `${message}`,
					animation: true,
					allowOutsideClick: false,
					onBeforeOpen: () => {
						this.$swal.showLoading()
					},
				})
			},
			getProvince(values){
				this.arrProvinces[0].children = [];
				values.forEach(async (x) => {
					await this.arrProvinces[0].children.push({
						id: x.value,
						label: x.text
					});
				});
				            
			},
			openWeatherModal(data) {
				if (data) {
					this.loadingDetail = true
					this.WeatherAdvisoryID = data.WeatherAdvisoryID
					this.getDetail(data.WeatherAdvisoryID).then((res) => {
						if (res) {
							this.weatherForm = {
								Year: res.Year,
								Commodity: res.Commodity,
								Country: res.Country,
								Province: res.Province,
								Month: res.Month,
								PlantPhase: res.PlantPhase,
								Humidity: res.Humidity,
								Event: res.Event,
								Temp: res.Temp,
								RainFall: res.RainFall,
								languages: res.language.map((v) => ({
									WeatherLanguageCode: v.WeatherLanguageCode,
									WeatherLangDoHeadline: v.WeatherLangDoHeadline,
									WeatherLangDontHeadline: v.WeatherLangDontHeadline,
									WeatherLangDoDescription: v.WeatherLangDoDescription,
									WeatherLangDontDescription: v.WeatherLangDontDescription,
								})),
							}
							this.loadingDetail = false
						}
					})
				}
				this.weatherModal = true
			},
			updateParams(newProps) {
				this.serverParams = Object.assign({}, this.serverParams, newProps)
			},
			onPageChange(params) {
				this.updateParams({ currentPage: params.currentPage })
				this.loadItems()
			},
			onPerPageChange(params) {
				this.updateParams({ rowPerpage: params.currentPerPage })
				this.loadItems()
			},
			onSortChange(params) {
				this.updateParams({
					sort: [
						{
							type: params[0].type,
							field: params[0].field,
						},
					],
					currentPage: 1,
				})
				this.loadItems()
			},
			onColumnFilter(params) {
				this.updateParams(params)
				this.loadItems()
			},
			onSearchFilter(params) {
				this.updateParams({
					columnFilters: { Year: params.searchTerm },
				})
				this.loadItems()
			},
			closeWeatherModal() {
				this.weatherForm = {
					Year: null,
					Commodity: null,
					Country: null,
					Province: [],
					Month: [],
					PlantPhase: null,
					Humidity: null,
					Event: null,
					Temp: null,
					RainFall: null,
					languages: [
						{
							WeatherLanguageCode: "in",
							WeatherLangDoHeadline: "",
							WeatherLangDontHeadline: "",
							WeatherLangDoDescription: "",
							WeatherLangDontDescription: "",
						},
					],
					Status: null,
				}
				this.weatherModal = false
			},
			getLanguages() {
				this.$http
					.Get({}, this.$urlApi.training.language)
					.then((r) => {
						this.$swal.closeModal()
						if (r.success) {
							r.results.sort((a, b) => parseFloat(a.id) - parseFloat(b.id))
							r.results.forEach((v) => {
								this.languages.push({
									value: v.language_code,
									text: v.language_desc,
								})
							})
						} else {
							this.$swal("Error!", r.error_massage, "error")
						}
					})
					.catch((e) => {
						this.$swal("Error!", e.response.data.message, "error")
					})
			},
			openFormLanguageList() {
				this.formLanguageList = true
			},
			closeFormLanguageList() {
				this.formLanguageList = false
			},
			loadItems() {
				this.loading = true
				this.getTablesGrid(this.serverParams).then((data) => {
					this.loading = false
					this.totalRecords = this.TotalRecords
					if (data) this.rows = data
				})
			},
			getCommodities() {
				this.commodities = []
				this.$http
					.Get({}, this.$urlApi.training.commodity)
					.then((r) => {
						if (r.success) {
							r.results.forEach((e) => {
								this.commodities.push({
									value: e.Code,
									text: e.Value,
								})
							})
						} else {
							this.$swal("Error!", r.error_massage, "error")
						}
					})
					.catch((e) => {
						this.$swal("Error!", e.response.data.error_message, "error")
					})
			},
			loadOption() {
				this.actGetProvince()
				this.actGetOption("weather_adv_month")
				this.actGetOption("weather_adv_plant_phase")
				this.actGetOption("weather_adv_humidity")
				this.actGetOption("weather_adv_event")
				this.actGetOption("weather_adv_rainfall")
				this.actGetOption("weather_adv_temp")
			},
			changeProv(id) {
				this.clearRegion()
				this.actGetDistrict(id)
			},
			saveWeather(data, status) {
				// eslint-disable-next-line consistent-return
				this.$validator.validateAll().then((result) => {
					if (!result) {
						return false
					}
					data.Status = status
					data.languages.forEach((v, i) => {
						if (i !== 0) {
							data.languages[i].WeatherLangDoHeadline = data.languages[0]?.WeatherLangDoHeadline
							data.languages[i].WeatherLangDontHeadline = data.languages[0]?.WeatherLangDontHeadline
						}
					})
					if (!this.WeatherAdvisoryID) {
						this.createWeather(data)
					} else {
						this.updateWeather(data, this.WeatherAdvisoryID)
					}
				})
			},
			addLanguage(lang) {
				let data = this.languages.find((v) => v.value === lang)

				this.weatherForm.languages.push({
					WeatherLanguageCode: data.value,
					WeatherLangDoHeadline: "",
					WeatherLangDontHeadline: "",
					WeatherLangDoDescription: "",
					WeatherLangDontDescription: "",
				})

				this.$swal("Added!", "Your data has been added.", "success")
				this.closeFormLanguageList()
			},
			deleteLanguageTab(i) {
				this.$swal({
					title: "Are you sure?",
					text: "Data will be deleted",
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "Yes",
				}).then((r) => {
					if (r.value) {
						this.weatherForm.languages.splice(i, 1)
					}
				})
			},
			createWeather(payload) {
				this.showLoadingMessage("Saving Data...")
				this.$http
					.Post(payload, this.$urlApi.weather.create)
					.then((r) => {
						if (r.success) {
							this.$swal.closeModal()
							this.$swal("Saved!", "Your data has been added.", "success")
							this.closeWeatherModal()
							this.loadItems()
						}
					})
					.catch((e) => {
						this.$swal.closeModal()
						this.$swal("Failed!", e.response.data.error_message, "error")
					})
			},
			updateWeather(payload, id) {
				this.showLoadingMessage("Updating Data...")
				payload.WeatherAdvisoryId = id
				this.$http
					.Put(payload, this.$urlApi.weather.update)
					.then((r) => {
						if (r.success) {
							this.$swal.closeModal()
							this.$swal("Saved!", "Your data has been updated.", "success")
							this.closeWeatherModal()
							this.loadItems()
						}
					})
					.catch((e) => {
						this.$swal.closeModal()
						this.$swal("Failed!", e.response.data.error_message, "error")
					})
			},
			getDetail(id) {
				return new Promise((resolve, reject) => {
					this.$http
						.Get({ WeatherAdvisoryID: id }, this.$urlApi.weather.detail.get)
						.then((r) => {
							if (r.success && r.results !== null) {
								resolve(r.results)
							} else {
								this.$swal("Error!", r.error_massage, "error")
							}
						})
						.catch((e) => {
							this.$swal("Error!", e.response.data.message, "error")
							reject(e)
						})
				})
			},
			getLanguageName(value) {
				return this.languages.find((v) => v.value === value)?.text
			},
			changeTab(index) {
				this.weatherForm.languages.forEach(() => {
					let w1 = this.weatherForm.languages[index]
					let w2 = this.weatherForm.languages[0]
					w1.WeatherLangDoHeadline = w2.WeatherLangDoHeadline
					w1.WeatherLangDontHeadline = w2.WeatherLangDontHeadline
				})
			},
			deleteWeather(item) {
				this.$swal({
					title: "Are you sure?",
					text: "Data will be deleted",
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "Yes",
				}).then((result) => {
					if (result.value) {
						this.showLoadingMessage("Deleting Data...")
						let payload = {
							WeatherAdvisoryId: item.WeatherAdvisoryID,
						}
						this.$http
							.Delete(payload, this.$urlApi.weather.delete)
							.then((response) => {
								if (response.success) {
									this.loadItems()
									this.$swal.closeModal()
									this.$swal("Deleted!", "Your data has been deleted.", "success")
								} else {
									this.$swal.closeModal()
									this.$swal("Error!", "process error", "error")
								}
							})
							.catch((error) => {
								this.$swal("Failed!", error.response.data.error_message, "error")
								// this.$swal("Error!", "process error", "error");
							})
					}
				})
			},
			getMonthName(val) {
				return this.months.find((v) => v.value === val)?.text
			},
		},
	}
</script>
<style scoped>
	.form-control {
		background-color: #ffffff !important;
	}
</style>
